import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import FeedPage from './FeedPage';
import ChatComponent from './ChatComponent';
import SignIn from './SignIn';
import useAuth from './useAuth';
import { MdPerson } from 'react-icons/md';

function App() {
  console.log("App component is mounting");
  
  const [activeButton, setActiveButton] = useState('home'); 
  const { user, displayName } = useAuth();

  return (
    <Router>
       <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', marginTop: '10px' }}>
        <Link to="/" style={{ marginRight: '5px' }}>
          <button className={`button-style ${activeButton === 'home' ? 'active' : ''}`}
            onClick={() => setActiveButton('home')}>mChatAI</button>
        </Link>
        <Link to="/feedpage" style={{ marginRight: '5px'}}>
          <button className={`button-style ${activeButton === 'iosApp' ? 'active' : ''}`}
            onClick={() => setActiveButton('iosApp')}>Feed</button>
        </Link>
        <Link to="/social-prompts" style={{ marginRight: '5px' }}>
          <button 
            className={`button-style ${activeButton === 'socialPrompts' ? 'active' : ''}`}
            onClick={() => setActiveButton('socialPrompts')}>Chat</button>
        </Link>
        <Link to="/signin" style={{ marginRight: '5px' }}>
          <button 
            className={`button-style ${activeButton === 'signin' ? 'active' : ''}`}
            onClick={() => setActiveButton('signin')}><MdPerson />
          </button>
        </Link>
        {user && (
          <span style={{ marginLeft: '10px' }}>{displayName}</span>
        )}
      </div>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/feedpage" element={<FeedPage />} />
          <Route path="/social-prompts" element={<ChatComponent user={user} />} />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
    </Router>
  );
}
export default App;
