import React from 'react';
import './App.css';

const HomePage = () => {
  return (
    <div className='homepage' style={{ marginLeft: '20px', marginTop: '10px', paddingLeft: '40px', paddingRight: '40px'  }} >
    <p></p>
      <p></p>
    <h1>mChatAI Home</h1>
    <div className="App-subHeader">
    <h2>Welcome to mChatAI: Home of AI Solutions!</h2>
      <p>mChatAI is a provider of unique AI features and capabilities.</p>
      
      <h1>mChatAI iOS App</h1>
    <div className="App-subHeader">
    <h2>Get the mChatAI iOS app!</h2>
      <p>The mChatAI iOS App is an mobile AI multi-tool providing unique capabilities!</p>
      
      <h3>Get it now on the Apple App Store:</h3>
      <a 
          className="App-link"
          href="https://apps.apple.com/us/app/mchatai/id6445939409?platform=iphone"
          target="_blank"
          rel="noopener noreferrer"
        >
           Get mChatAI on the AppStore
        </a>

      <p>mChatAI is an AI iOS app that lets you chat with a powerful AI conversationally.  Ask mChatAI to generate ideas, songs, code and content, revise and edit, and even tell jokes. Just ask it a natural question, and mChatAI will come back with inspiring results.  Save AI results you like for later usage, and copy those results to other iPhone Apps.  Edit photos, make photo variations, create a ToDo list and let AI give you feedback, and suggestions.  24 different AI Persona's for conversing with.  AI Widgets can bring AI summaries to your home screen.  Use TourGuideAI to discover interesting places around you or anywhere.  </p>
      
      <p></p>
      <p>New with version 1.40: Try out AI on your ToDo list! With mChatAI's ToDo Applet, you can get some AI insights on your ToDo's and Goals; be able to drill in and get AI analysis and research suggestions.  Also in this release: 24 new AI persona's to converse with!  Bug fixes across the App.  Make 5 free ChatGPT calls per day. </p>
      <p></p>
      <p></p>
      <p></p>
      </div>
      
      <h3>mChatAI Web:</h3>
      <p>mChatAI.com - AI web solutions.</p>
      <p></p>
      <p style={{ marginBottom: '100px' }}> </p>
      </div>
    
    <div>
    <p></p>
    <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    <h2>mChatAI Privacy Policy and Terms of Use</h2>
    <div className="App-subHeader">
    <h2>Privacy Policy</h2>
      <p>This policy applies to all information collected or submitted on mChatAI’s website and our apps for iPhone and any other devices and platforms.</p>
      
      <h3>Information we collect:</h3>
      <p>As of mChatAI Version 1.34 build 54, mChatAI uses Firebase Analytics to understand user behavior and enhance the user experience. The data collected is aggregated and not personally identifiable.</p>
      
      <h3>Technical basics:</h3>
      <p>We utilize Firebase Analytics to collect usage data, which helps us improve the app. This data is anonymized and does not contain personal information.</p>
      
      <h3>Advertisements and Third-party Services:</h3>
      <p>To support the app's operations, mChatAI integrates Appodeal for displaying ads. While mChatAI does not retain any user identifiable information, Appodeal and the third-party advertisers it collaborates with may collect and use data according to their own privacy policies. For more details, see <a href="https://appodeal.com/ccpa-privacy-policy/" target="_blank" rel="noopener noreferrer">Appodeal's Privacy Policy</a>. Users have the option to opt in or out of tracking consent related to these advertisements.</p>
      
      <h3>California Online Privacy Protection Act Compliance:</h3>
      <p>We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</p>
      
      <h3>Children’s Online Privacy Protection Act Compliance:</h3>
      <p>We never collect or maintain information at our website from those we actually know are under 13, and no part of our website or app is designed for or targets children under 13.</p>
      
      <h3>Information for European Union Customers:</h3>
      <p>By using mChatAI and providing your information, you authorize us to collect, use, and store your information outside of the European Union.</p>
      
      <h3>International Transfers of Information:</h3>
      <p>Information may be processed, stored, and used outside of the country in which you are located. Data privacy laws vary across jurisdictions, and different laws may be applicable to your data depending on where it is processed, stored, or used.</p>
      
      <h3>Your Consent:</h3>
      <p>By using our site or apps, you consent to our privacy policy.</p>
      
      <h3>Contacting Us:</h3>
      <p>If you have questions regarding this privacy policy, you may email <a href="mailto:loopstarapp@gmail.com">loopstarapp@gmail.com</a></p>
      <p></p>
      <p></p>
     
      <p></p>
      <p></p>
      <h1>mChatAI  iOS App Terms of Use</h1>
      
      <p></p>
        
      The terms of use for the mChatAI mobile application for iOS (“iOS App Terms of Use”) is a legally binding agreement. Please read this document carefully. By clicking “I Agree,” or installing or using the mChatAI mobile application for iOS software and/or any updates to such software (collectively, “App”) provided by 7hillsStudios and/or its affiliates (collectively, mChatAI), you Agree to the following terms as an authorized User of the entity with which you are employed, affiliated, or associated (“Customer”), which may include has concluded a agreement for a mChatAI features (“Agreement”).
      <p></p>                                
      1. GENERAL TERMS
      <p></p>                                
      1.1  You represent that you are an authorized User under the Agreement. If you do not qualify as an authorized User, then you may download the App, but will not be able to access the mChatAI full feature set. 						 							
      <p></p>
      1.2  The iOS App Terms of Use is governed by the terms of the Agreement and any amendment thereto or additional purchase thereunder. The iOS App Terms of Use adjusts and/or supplements certain terms of the Agreement, solely with respect to the App. Capitalized terms used but not defined in the iOS App Terms of Use have the meanings given to them in the Agreement. 												 							
      <p></p>
      1.3  The App contains a features that allows a Customer User to use OpenAI's features via their APIKeys. 						 							
      <p></p>
      1.4  If Customer User uses the App, Customer Data will not be transferred to and from the respective technical infrastructure systems that support mChatAI, including servers administered by Amazon Web Services, Inc. (“AWS”). Additional information regarding these technical infrastructure systems and the rules that apply to such transfers is available in the Agreement. 						 							
      <p></p>
      1.5  Apple Inc. may, at any time and without notice, restrict, interrupt, or prevent use of the App, or delete the App from your or Customer's Apple device(s), or require mChatAI to do any of the foregoing, without entitling Customer or you to any refund, credit, or other compensation from mChatAI or any third party (including, but not limited to, Apple Inc. or your network connectivity provider). 												 							
      <p></p>
      1.6  The iOS App Terms of Use is located at: https://www.apple.com/legal/internet-services/itunes/dev/stdeula/. It is effective between you and mChatAI as of the date you first download, install, or use the App, whichever occurs earliest and can be accessed and downloaded at: https://www.chataifree.com/. mChatAI may update and change any part or all of the iOS App Terms of Use. 
      The updated version will be posted publicly on mChatAI's website at the above link and will be effective and binding on the day after posting. 
      mChatAI encourages Customer and Users to review the iOS App Terms of Use periodically, as you are responsible for reading and complying with any amended version(s). If Customer or User objects to any such changes, Customer’s or User’s sole recourse shall be to cease using the App. Continued use of the App following the relevant Version Effective Date shall indicate Customer's and User’s acknowledgement of such changes and agreement to be bound by the updated iOS App Terms of Use.
      <p></p>
      1.7 You acknowledge and agree to mChatAI's Privacy Policy, which can be accessed and downloaded at: https://www.chataifraee.com/ 
      <p></p>                                
      2. LICENSE TO USE THE APP
      <p></p>                            
      2.1  The App, including software embedded in the App, is licensed, not sold, to Customer by mChatAI only under the terms of the Agreement and mChatAI reserves all rights not expressly granted to Customer. The App contains some open source software libraries, the license terms of which are available on written request to mChatAI. mChatAI and its licensors retain ownership of the software contained in the App. 						 							
      <p></p>
      2.2  Unless otherwise specified in the Agreement and subject to payment of the Subscription Fee and any other undisputed amounts due, mChatAI grants to Customer, and subsequently you as User, a limited, non-exclusive, non-assignable, non-transferable, renewable, and revocable license during the applicable Term, to access and use the App on any Supported Device and on no other devices, exclusively for Customer’s internal business purposes, as set out in the Agreement. 
      The license granted to User and Customer for the App is a non-transferable license to use the App on any iOS devices that User owns or controls and as permitted by Apple (“Supported Devices”). 						 							
      <p></p>
      2.3  Such license permits you to use Intellectual Property Rights and Confidential Information included in the App and to make copies of software or other information strictly as necessary to download, install, and use the App. Such license shall not be construed to mean, by inference or otherwise, that Customer has any right to access or obtain any source code for the App. 										 							
      <p></p>
      2.4  Without limiting the generality of anything herein, you acknowledge and agree that the App may collect use or device data for the purposes of providing services or functions that are relevant to use of the App. 						
      <p></p>                                                 
      3. APPLE TERMS
      <p></p>                        
      3.1  mChatAI, and not Apple, is solely responsible for the App and the license granted herein. Apple has no obligation to provide maintenance and support for the App. 						
      <p></p>
      3.2  User’s and Customer's use of the App must comply with the Usage Rules set forth in the Apple App Store Terms of Use, which can be accessed and downloaded at: https://www.apple.com/legal/internet-services/itunes/us/terms.html.				
      <p></p>
      3.3  The App is not covered by any service level agreement between Customer and mChatAI. Nevertheless, mChatAI will aim to provide customer support for the App to the same standard as set out in the Agreement.
      <p></p>
      3.4  In order to respond to FAQs and help its customers to resolve common problems without needing	 direct assistance from Support, mChatAI maintains the Knowledge Base on the mChatAI website (mChatAI.com). If your question is not resolved via the Knowledge Base, the mChatAI help desk can be contacted by email anytime via loopstarapp@gmail.com				
      <p></p>                                                     
      3.5  Apple is not responsible for addressing, investigating, defending, settling, or discharging any claim brought by Customer or any third party for allegations relating to the App, or your or Customer's possession and/or use of the App, including but not limited to: (a) product liability; (b) any failure of the App to comply with applicable legal or regulatory requirements; (c) consumer protection or similar legislation; or (d) infringement of third party intellectual property rights. 						 							
      <p></p>
      3.6  As a User, you represent and warrant that: (a) the App will not be downloaded in, used in, or transported to a country that is subject to a U.S. Government embargo or EU government sanctions, or that has been designated by the U.S. Government or any EU government as a "terrorist-supporting" country or similar; and (b) neither Customer nor User is listed on any U.S. Government or EU government list of prohibited or restricted parties. 						 							
      <p></p>
      3.7  mChatAI's address is 1108 13th Ave Seattle WA, USA. Any claims regarding the App may be submitted via email to loopstarapp@gmail.com 						 							
      <p></p>
      3.8  Notwithstanding anything to the contrary in the Agreement, Apple and its subsidiaries are third- party beneficiaries of the iOS App Terms of Use, and have the right (and shall be deemed to have accepted the right) to enforce the iOS App Terms of Use against you and Customer. 												 							
      <p></p>
      3.9  To the maximum extent permitted by applicable law, Apple will have no warranty obligation whatsoever with respect to the App, and will not be liable for any claims, losses, liabilities, damages, costs, or expenses attributable to any failure of the App to conform to any warranty. Apple shall not be required to provide a refund to you or Customer under any circumstances. 						
      <p></p>                                                
      4. TERM AND TERMINATION
      <p></p>                             
      4.1  mChatAI may terminate access to the App at any time upon thirty (30) days' notice to Customer without cause, or immediately upon notice to Customer if: any third party (including Apple or your network connectivity provider) restricts, prevents, or ceases to authorize the installation or use of the App on your Supported Device or over your network. In addition, the iOS App Terms of Use shall terminate immediately and automatically upon the termination or expiration of Customer's mChatAI subscription for whatever reason. Upon any such termination or expiration, Customer and its Users will no longer be permitted to use the App, and will delete or destroy all copies of the App in its possession, including on all devices of its Users. 												 							4.2  Termination of the iOS App Terms of Use shall not entitle Customer to any refund, credit, or other compensation from mChatAI under the Agreement or any other agreement or from any third party.  						
                                                               
      <p></p>
      <p></p>
      <p></p>
      </div>
  </div>
  </div>
);
};
  export default HomePage;