import React, { useState, useEffect, useRef, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import { getChatResponse } from './graphql/queries'; // Adjust the import path as necessary
import { createChatResponse, updateChatResponse } from './graphql/mutations';
import ChatGPTInput from './ChatGPTInput'; 
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi'; // For the gear icon
import './ChatComponent.css';

const getCurrentUserInfo = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      const displayName = userInfo.attributes['custom:displayName'] || userInfo.username;
      const authorID = userInfo.attributes.sub; // Cognito User Pool sub attribute is the user's unique ID
      return { displayName, authorID };
    } catch (error) {
      console.warn('Unable to fetch user info (user not logged in):', error);
      return { displayName: 'Anonymous', authorID: null };
    }
  };

const storeChatGPTResponse = async (query, chatResponse, conversationHistory) => {
    const { displayName, authorID } = await getCurrentUserInfo();
    let responseDetails;

    let chatResponseId = JSON.parse(localStorage.getItem('chatResponseId'));
    console.log("storeChatGPTResponse chatResponseId: " + chatResponseId);
    if (chatResponseId) {
        // Update existing conversation
        // Append the current query and response to the conversation history
        console.log("query: " + query);
        console.log("chatResponse: " + chatResponse);
        const currentQueryMessage = { role: 'user', content: query };
        const currentResponseMessage = { role: 'assistant', content: chatResponse };
        console.log("currentQueryMessage: " + currentQueryMessage);
        console.log("currentResponseMessage: " + currentResponseMessage);

        console.log("conversationHistory: " + conversationHistory);
        let historyArray = typeof conversationHistory === 'string' ? JSON.parse(conversationHistory) : conversationHistory;
        console.log("historyArray: " + historyArray);

        // Update the conversation history with the latest query and response
        const updatedConversationHistory = [...conversationHistory, currentQueryMessage, currentResponseMessage];
        console.log("updatedConversationHistory: " + JSON.stringify(updatedConversationHistory));
        responseDetails = {
            id: chatResponseId,
            query,
            response: chatResponse,
            authorDisplayName: displayName,
            authorID: authorID,
            gptModel: "gpt-3.5-turbo",
            category: "ChatResponse",
            convo: JSON.stringify(updatedConversationHistory)
        };
        try {
            await API.graphql(graphqlOperation(updateChatResponse, { input: responseDetails }));
        } catch (error) {
            console.error('Error updating chat response:', error);
        }
    } else {
        // Create new conversation
        responseDetails = {
            query,
            response: chatResponse,
            createdAt: new Date().toISOString(),
            authorDisplayName: displayName,
            authorID: authorID,
            gptModel: "gpt-3.5-turbo",
            category: "ChatResponse",
            convo: JSON.stringify(conversationHistory)
        };
        try {
            const response = await API.graphql(graphqlOperation(createChatResponse, { input: responseDetails }));
            const newChatResponseId = response.data.createChatResponse.id; // Get new chat response ID
            localStorage.setItem('chatResponseId', JSON.stringify(newChatResponseId));
            console.log("storeChatGPTResponse chatResponseId: " + newChatResponseId);
            return response.data.createChatResponse.id; // Return new chat response ID
        } catch (error) {
            console.error('Error creating chat response:', error);
            return null;
        }
    }
  };  

function ChatComponent({ user }) {
  const [currentQuery, setCurrentQuery] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [webSocket, setWebSocket] = useState(null);
  const currentQueryRef = useRef('');
  const [chatTitle, setChatTitle] = useState('');
  const [showWikiButton, setShowWikiButton] = useState(true);
  const [wikiTopic, setWikiTopic] = useState(''); 
  const [wikiInfo, setWikiInfo] = useState('');
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [wikiLoading, setWikiLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]); 
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [renderedConversation, setRenderedConversation] = useState(null); // Initialize state to hold rendered conversation JSX

  useEffect(() => {
    // Fetch the last chatResponseId from localStorage
    const lastChatResponseId = localStorage.getItem('chatResponseId');
  
    if (lastChatResponseId) {
      // If there's a stored chatResponseId, fetch the corresponding conversation
      fetchConversation(lastChatResponseId);
    }
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts
  
  useEffect(() => {
    const checkUser = async () => {
        try {
            const userInfo = await Auth.currentAuthenticatedUser();
            setCurrentUser(userInfo); // Setting the user info in state
        } catch (error) {
            console.warn('User not logged in:', error);
            setCurrentUser(null); // Setting to null if not logged in
        }
    };

    checkUser();
  }, []); // Empty dependency array means this runs on component mount


  const updateConversationHistory = (newMessage) => {
    setConversationHistory(prevHistory => {
      console.log("Current History: " + conversationHistory)
      // Check if the new message is already the last one in the history to avoid duplicates
      const lastMessage = prevHistory[prevHistory.length - 1];
      if (lastMessage && lastMessage.content === newMessage.content && lastMessage.role === newMessage.role) {
        return prevHistory; // Return the existing history if the new message is a duplicate
      } else {
        return [...prevHistory, newMessage]; // Append the new message and return the updated history
      }
    });
  };

  useEffect(() => {
    
      var responseStr = ""
      // --> VCAI_TopLevelOrchWebSocket
      const socket = new WebSocket('wss://nkfq5tqa08.execute-api.us-west-2.amazonaws.com/production/');

      const onOpen = () => console.log('WebSocket Connected');
      const onClose = () => console.log('WebSocket Disconnected');
      const onError = (error) => console.error('WebSocket Error', error);

      // Connection opened
      socket.addEventListener('open', (event) => {
          console.log('Connected to WS Server');
      });

      socket.addEventListener('close', onClose);
      socket.addEventListener('error', onError);

      // Listen for messages
      socket.addEventListener('message', async (event) => {
          const data = JSON.parse(event.data);
          setIsLoading(false); // Stop showing the loading icon
          //console.log('Received data:', data); // Log the entire data object
          if (data.status && data.status === "initial_done") {
              // Update UI to indicate that initial processing is done
              // For example, show a loading spinner or a message
              console.log('data.status: initial_done');
          } else if (data.status && data.status === "post_processing_done") {
              console.log('Received post-processing data:', data);
              // Parse the body to extract title and isWikiRelevant
              let parsedBody;
              try {
                  parsedBody = JSON.parse(data.body);
              } catch (error) {
                  console.error('Error parsing post-processing body:', error);
                  // Handle the error appropriately
              }
              if (parsedBody) {
                  console.log('Parsed post-processing data:', parsedBody);
                  if (parsedBody.title) {
                      setChatTitle(parsedBody.title);
                  }
                  
                  setWikiTopic(parsedBody.isWikiRelevant || '');
              }
          } else if (data.status && data.status === "wiki_info") {
              //console.log('wiki_info - data.wikiSummary:', data.wikiSummary);
              setWikiLoading(false); // Disable spinner in case of error
              if (data.wikiSummary) {
                setWikiInfo(formatWikiText(data.wikiSummary));
              } else {
                setWikiInfo("No detailed information available.");
              }
          }
          else {
              // Handle other messages (including the final post-processing data)
              //console.log('data.status: other status' + data.status);
          }
          
          if (data.content) {
            //console.log('data.content: ' + data.content);
            const contentToAdd = String(data.content);
            setResponse(prev => prev ? (prev + contentToAdd) : contentToAdd);
            responseStr += contentToAdd;
            //console.log('response is: ' + responseStr);
          } else if (data.message) {
              console.log('data.message: ' + data.message);
              // TODO: handle return of : "Endpoint request timed out"  ??  -> This is where it comes in:
          }

          if (data.endOfStream) {
            // Remove leading and trailing double quotes from responseStr
            let trimmedResponse = responseStr;
            if (trimmedResponse.startsWith('"') && trimmedResponse.endsWith('"')) {
                trimmedResponse = trimmedResponse.substring(1, trimmedResponse.length - 1);
            }
            //console.log('Trimmed response:', trimmedResponse);   
            if (trimmedResponse && trimmedResponse.trim().length > 0) {
                let formattedResponse = trimmedResponse.replace(/\n/g, '<br />');
              
                let combinedConversationHistory = [...conversationHistory];
                const lastMessage = combinedConversationHistory[combinedConversationHistory.length - 1];
                
                if (currentQueryRef && currentQueryRef.current && (!lastMessage || lastMessage.content !== response)) {
                  combinedConversationHistory.push({
                    role: 'user',
                    content: currentQueryRef.current,
                  });
                }

                if (trimmedResponse && (!lastMessage || lastMessage.content !== response)) {
                  combinedConversationHistory.push({
                    role: 'assistant',
                    content: trimmedResponse,
                  });
                }

                console.log("in updateConversationHistory: " + conversationHistory);
                const newChatResponseId = await storeChatGPTResponse(currentQueryRef.current, trimmedResponse, JSON.stringify(combinedConversationHistory));
                console.log("storeChatGPTResponse chatResponseId: " + newChatResponseId);
            } else {
                console.log('Error: response = false!');
            }
            responseStr = "";
            return; // Exit the function
          }        
      });

      // Update the state with the new WebSocket
      setWebSocket(socket);

      // Clean up on unmount
      return () => {
          socket.removeEventListener('open', onOpen);
          socket.removeEventListener('close', onClose);
          socket.removeEventListener('error', onError);
          socket.close();
      };
  }, []); // Dependency array is empty as we only want this to run once on component mount

  useEffect(() => {
    const storedChatResponseId = JSON.parse(localStorage.getItem('chatResponseId'));

    if (storedChatResponseId) {
        fetchConversation(storedChatResponseId);
    }
  }, []); // Dependency array is empty, so this effect runs only once on mount

  useEffect(() => {
    if (selectedConversation) {
      // Call renderConversation or perform any actions needed when a conversation is selected
      // Since renderConversation returns JSX, you might want to store this in a state
      // to render it in your component's return statement
      const renderedConvo = renderConversation(
        selectedConversation.convo, 
        selectedConversation.authorDisplayName, 
        selectedConversation.id
      );
      // Assuming you have a state to store the rendered conversation JSX
      setRenderedConversation(renderedConvo);
      
      //Load the seleced convo  
      setConversationHistory(JSON.stringify(selectedConversation.convo));
    }
  }, [selectedConversation]); // This effect runs whenever selectedConversation changes
  

  const fetchUserConversations = async (authorID) => {
    try {
      const response = await API.graphql(graphqlOperation(queries.listChatResponsesByAuthor, {
        authorID: authorID,
        sortDirection: 'DESC', // Assuming you want the newest conversations first
      }));
      const conversations = response.data.listChatResponsesByAuthor.items;
      setConversations(conversations); // Update the state with fetched conversations
      console.log('User conversations:', conversations);
      return conversations;
    } catch (error) {
      console.error('Error fetching user conversations:', error);
    }
  };

  const handleHistoryClick = async () => {
    setIsModalVisible(true); 

    const { authorID } = await getCurrentUserInfo();
    if (authorID) {
      fetchUserConversations(authorID);
    } else {
      console.log("User is not logged in or authorID is not available.");
    }
  };

  const handleConversationSelect = (conversation) => {
    setSelectedConversation(conversation); // Set the selected conversation
    setIsModalVisible(false); // Hide the modal
  };

  const fetchConversation = async (chatResponseId) => {
    try {
        const chatResponse = await API.graphql(graphqlOperation(getChatResponse, { id: chatResponseId }));

        if (chatResponse.convo  == undefined) {
          console.log('fetchConversation Failed: response.convo: ' + chatResponse.convo );
          return null;
        }
        console.log('fetchConversation response.convo: ' + chatResponse.convo );
        console.log('fetchConversation response.getChatResponse: ' + chatResponse.getChatResponse );
        const chatResponseData = chatResponse.data.getChatResponse;

        if (chatResponseData && chatResponseData.convo) {
            // Assuming 'convo' is a JSON string containing the conversation history
            const conversationHistoryData = JSON.parse(chatResponseData.convo);

            // Set the fetched conversation history into your component's state
            setConversationHistory(conversationHistoryData);

            // Update any other state you need, based on the fetched chat response data
        } else {
            console.log('No conversation found for the given chatResponseId: ' + chatResponseId );
            localStorage.setItem('chatResponseId', JSON.stringify(null));
        }
    } catch (error) {
        console.error('Error fetching conversation:', error);
    }
  };

  // ... existing handleUserQuery and other related functions ...
  const handleUserQuery = (query) => {
    setError(''); // Reset error message
    setIsLoading(true);
    setResponse('');
    setCurrentQuery(query); 
    currentQueryRef.current = query; 
    console.log("Preparing to send WebSocket message with query:", query);

    updateConversationHistory({ role: 'user', content: query });
  };

  // useEffect that sends the WebSocket message when conversationHistory updates
  useEffect(() => {
    if (conversationHistory.length > 0 && webSocket && webSocket.readyState === WebSocket.OPEN) {
      const lastMessage = conversationHistory[conversationHistory.length - 1];
      if (lastMessage.role === 'user' && lastMessage.content === currentQueryRef.current) {
        console.log("Sending WebSocket message with updated conversation history");
        console.log("conversationHistory during query: " + JSON.stringify(conversationHistory));
        webSocket.send(JSON.stringify({
          action: 'sendMessage',
          data: currentQueryRef.current,
          convo: conversationHistory // Send the entire updated conversation history
        }));
      }
    }
  }, [conversationHistory, webSocket]); // Add webSocket to the dependency array if its reference might change over time

  const handleWikiButtonClick = () => {
    // Implement logic to fetch and display Wikipedia summary

      if (!currentUser) { // Check if user is logged in
        // Prompt user to log in
        alert("Please log in to get Wikipedia information.");
        // Optionally, redirect to a login page or open a login modal here
        // Example: history.push('/login'); // if you are using react-router
        navigate('/signin');
        return; // Exit the function to prevent further execution
      }

      console.log('Wikipedia button clicked');
      setWikiLoading(true); // Enable spinner
      //setResponse(prev => prev + "\n\nWikiInfo:\n" );
      if (wikiTopic) {
        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
          webSocket.send(JSON.stringify({
            action: 'sendMessage',
            data: `GetWikipediaInfo:${wikiTopic}` // wikiTopic is the topic received from post-processing
          }));
          // hide wikitopic after it's used to hide the wiki button... But does not quite seem to work:
          setWikiTopic(''); 
          setShowWikiButton(false); 
        } else {
          console.error('WebSocket is not open.');
          setWikiLoading(false); // Disable spinner in case of error
        }
      } else {
        console.error('No Wikipedia topic available.');
        setWikiLoading(false); // Disable spinner in case of error
      }
  };

  const handleNewConversation = () => {
    // Reset the states related to the conversation
    setCurrentQuery('');
    setResponse('');
    setChatTitle('');
    setConversationHistory([]);
    setWikiInfo('')
    setWikiTopic('')
    setShowWikiButton(false); 
    localStorage.setItem('chatResponseId', JSON.stringify(null)); // or JSON.stringify('') for an empty string
    // Reset any other state variables related to the conversation display or storage here
  };

  const linkify = (inputText) => {
    if (!inputText) {
      return ""; // Return an empty string if inputText is undefined or null
    }

    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\._]+@[a-zA-Z0-9\-\._]+\.[a-zA-Z]{2,}))/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  };

  const linkifyWiki = (inputText) => {
    const linkRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    const parts = inputText.split(linkRegex);

    return parts.map((part, i) => {
        if (part.match(linkRegex)) {
            // Returns an anchor tag for URLs
            return <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
        } else {
            // Returns plain text for non-URL parts
            return part;
        }
    });
  };
  const formatWikiText = (text) => {
    return text.split('\n').map((item, key) => (
        <span key={key}>
            {linkifyWiki(item)}
            <br/>
        </span>
    ));
  };

  useEffect(() => {
    // If wikiTopic is non-empty, show the Wiki button
    setShowWikiButton(wikiTopic !== '');
  }, [wikiTopic]); // Dependency array ensures this runs only when wikiTopic changes

  const toggleMenu = () => setIsMenuVisible(prev => !prev);

  const formattedConversationHistory = useMemo(() => {
    let combinedConversationHistory = [...conversationHistory];
  
    // Check if the last message in the conversation history is not the same as the current response
    const lastMessage = combinedConversationHistory[combinedConversationHistory.length - 1];
    if (response && (!lastMessage || lastMessage.content !== response)) {
      combinedConversationHistory.push({
        role: 'assistant',
        content: response,
      });
    }
  
    return combinedConversationHistory.filter(chat => chat && chat.content && chat.content.trim()).map((chat, index) => {
      // Now we only map over chats that have passed the filter, so no need to check again here
      return (
        <div key={index} className={chat.role === 'user' ? 'queryStyle' : 'responseStyle'}>
          <span className={chat.role === 'user' ? 'queryAuthorStyle' : 'responseAuthorStyle'}>
            {chat.role === 'user' ? (chat.authorDisplayName || 'User') + ':' : 'ChatGPT:'}
          </span>
          <div dangerouslySetInnerHTML={{ __html: linkify(chat.content) }} />
        </div>
      );
    }); // Since we're using filter before map, all mapped items are guaranteed to have valid content, so no need to filter again after map
    


  }, [conversationHistory, response]); // Removed currentQuery from dependencies as it's not being used in the computation
  

  const renderConversation = (convo, authorDisplayName, chatResponseId) => {
    try {
      const stringifiedArray = JSON.parse(convo); // First parse to get the stringified array
      const conversationArray = JSON.parse(stringifiedArray); // Second parse to get the actual array
      localStorage.setItem('chatResponseId', JSON.stringify(chatResponseId));
      console.log("storeChatGPTResponse chatResponseId: " + chatResponseId);
      console.log("conversationArray.length " + conversationArray.length)
      console.log("conversationArray " + JSON.stringify(conversationArray))
      if (conversationArray.length == 0) {
        return null;
      }

      return conversationArray.filter(message => {
        return message.content && message.content.trim(); // Ensure content is not null, undefined, or just whitespace
      }).map((message, index) => (
        <div key={index} className={`message ${message.role}`} style={{ marginBottom: '2em' }}>
          <b>{message.role === 'user' ? (authorDisplayName || 'User') + ':' : 'ChatGPT:'}</b>
          <div dangerouslySetInnerHTML={{ __html: linkify(message.content) }} />
        </div>
      ));
    } catch (error) {
      console.error('Error parsing conversation:', error);
      return <div>Failed to load conversation.</div>;
    }
  };
  

  return (
    <div className="chatBoxContainerStyle">
      {chatTitle && <h3 style={{ textAlign: 'center' }}>{chatTitle}</h3>}

      <div className="chatHistoryStyle">
        {renderedConversation && (
          <div className="selectedConversationRendered">
            {renderedConversation}
          </div>
        )}
        {formattedConversationHistory}
        {isModalVisible && (
          <div className="modal">
            <div className="modalContent">
              <h3>Chat History</h3>
              {conversations.filter(conversation => conversation.query.trim() !== "").map((conversation) => (
                <div key={conversation.id} onClick={() => handleConversationSelect(conversation)} className="conversationItem">
                  <p>{conversation.query}</p>
                </div>
              ))}
              <button onClick={() => setIsModalVisible(false)}>Close</button>
            </div>
          </div>
        )}

      
      {/* Display selected conversation in the main view 
      {selectedConversation && (
        <div className="selectedConversation">
          {renderConversation(selectedConversation.convo, selectedConversation.authorDisplayName, selectedConversation.id)}
        </div>
      )}
      */}
        {/* Wikipedia Info Bubble */}
        {wikiInfo && showWikiButton && (
          <div className="responseStyle wikiInfoStyle">
            <span className="responseAuthorStyle wikiInfoTitle">Wikipedia Info:</span>
            <div>{wikiInfo}</div>
          </div>
        )}
        {wikiTopic && (
          <div style={{ textAlign: 'center', marginTop: '25px' }}>
            <button onClick={handleWikiButtonClick}>
              Get Wikipedia Info for: {wikiTopic}
            </button>
          </div>
        )}
      </div>
      <div className="chatInputContainerStyle">
        {isLoading && <div>Loading... <div className="spinner"></div></div>}
        {wikiLoading && <div>Loading Wikipedia Info... <div className="spinner"></div></div>}
        {error && <div className="error">{error}</div>}
        
        <div className="inputAndIconContainer">
          <div className="settingsIconContainer" onClick={toggleMenu}>
            <FiSettings size={24} />
            {isMenuVisible && (
              <div className="menuPopup">
                <ul>
                  <li onClick={handleNewConversation}>New</li> 
                  <li onClick={handleHistoryClick}>History</li>
                </ul>
              </div>
            )}
          </div>
          <ChatGPTInput onSubmit={handleUserQuery} />
        </div>
      </div>

    </div>
  );

}

export default ChatComponent;



  /*

const storeChatGPTResponse = async (query, chatResponse, conversationHistory) => {
    const { displayName, authorID } = await getCurrentUserInfo();
    let responseDetails;

    let chatResponseId = JSON.parse(localStorage.getItem('chatResponseId'));
    console.log("storeChatGPTResponse chatResponseId: " + chatResponseId);

    // Ensure conversationHistory is an array of objects
    let historyArray = typeof conversationHistory === 'string' ? JSON.parse(conversationHistory) : conversationHistory;

    if (chatResponseId) {
        console.log("query: " + query);
        console.log("chatResponse: " + chatResponse);
        const currentQueryMessage = { role: 'user', content: query };
        const currentResponseMessage = { role: 'assistant', content: chatResponse };
        
        // Update the conversation history with the latest query and response
        const updatedConversationHistory = [...historyArray, currentQueryMessage, currentResponseMessage];
        console.log("updatedConversationHistory: " + JSON.stringify(updatedConversationHistory));
        
        responseDetails = {
            id: chatResponseId,
            query,
            response: chatResponse,
            authorDisplayName: displayName,
            authorID: authorID,
            gptModel: "gpt-3.5-turbo",
            category: "ChatResponse",
            convo: JSON.stringify(updatedConversationHistory)
        };

        try {
            await API.graphql(graphqlOperation(updateChatResponse, { input: responseDetails }));
        } catch (error) {
            console.error('Error updating chat response:', error);
        }
    } else {
        // Create new conversation
        responseDetails = {
            query,
            response: chatResponse,
            createdAt: new Date().toISOString(),
            authorDisplayName: displayName,
            authorID: authorID,
            gptModel: "gpt-3.5-turbo",
            category: "ChatResponse",
            convo: JSON.stringify(historyArray)
        };

        try {
            const response = await API.graphql(graphqlOperation(createChatResponse, { input: responseDetails }));
            const newChatResponseId = response.data.createChatResponse.id;
            localStorage.setItem('chatResponseId', JSON.stringify(newChatResponseId));
            console.log("storeChatGPTResponse chatResponseId: " + newChatResponseId);
            return newChatResponseId;
        } catch (error) {
            console.error('Error creating chat response:', error);
            return null;
        }
    }
};  */


  
  /*
  const renderConversation = (convo, authorDisplayName, chatResponseId) => {
    try {
      //console.log('convo: ' + convo);
      
      localStorage.setItem('chatResponseId', JSON.stringify(chatResponseId));

      // Parse the convo JSON string to an array
      // First parse: Convert the JSON-encoded string to an actual string
      const decodedString = JSON.parse(convo);

      console.log("renderConversation - conversationArray" + conversationArray)

      // Second parse: Convert the string (which is actually a JSON array in string form) to an array
      const conversationArray = JSON.parse(decodedString);

      console.log("renderConversation - conversationArray" + conversationArray)

      return conversationArray.map((message, index) => (
        <div key={index} className={`message ${message.role}`} style={{ marginBottom: '2em' }}>
          <b>{message.role === 'user' ? (authorDisplayName || 'User') + ':' : 'ChatGPT:'}</b>
          <div dangerouslySetInnerHTML={{ __html: linkify(message.content) }} />
        </div>
      ));
    } catch (error) {
      console.error('Error parsing conversation:', error);
      return <div>Failed to load conversation.</div>;
    }
  };  */


//const [displayFromConvo, setDisplayFromConvo] = useState(false);
  //const [chatResponseId, setChatResponseId] = useState(null);
  

  
                /*
                const userMessage = {
                  role: 'user',
                  content: currentQueryRef.current
                };

                const chatGptCompleteResponse = {
                  role: 'assistant',
                  content: trimmedResponse
                };*/

                
                //const updatedConversationHistory = [...conversationHistory, userMessage, chatGptCompleteResponse];
                //setConversationHistory(updatedConversationHistory);
                

                // Call this function when you receive a new query
                //updateConversationHistory({ role: 'user', content: currentQuery.current });

                // Call this function when you receive a new response or a part of a streaming response
                //updateConversationHistory({ role: 'assistant', content: trimmedResponse });





/*
    // Add the user message to the conversation history using functional update form
    setConversationHistory((prevHistory) => {
      const userMessage = { role: 'user', content: query };
      return [...prevHistory, userMessage];
    });*/


  /*
  const formatConversationHistory = () => {
    // Clone the current conversation history to avoid modifying the state directly
    let combinedConversationHistory = [...conversationHistory];
  
    // If there's a current query, add it to the combined conversation history
    if (currentQuery) {
      combinedConversationHistory.push({
        role: 'user',
        content: currentQuery,
      });
    }
  
    // If there's a response (including a streaming response), add it to the combined conversation history
    if (response) {
      combinedConversationHistory.push({
        role: 'assistant',
        content: response,
      });
    }
    console.log("formatConversationHistory - combinedConversationHistory" + combinedConversationHistory)
      
    // Render the combined conversation history
    return combinedConversationHistory.map((chat, index) => (
      <div key={index} className={chat.role === 'user' ? 'queryStyle' : 'responseStyle'}>
        <span className={chat.role === 'user' ? 'queryAuthorStyle' : 'responseAuthorStyle'}>
          {chat.role === 'user' ? (chat.authorDisplayName || 'User') + ':' : 'ChatGPT:'}
        </span>
        <div dangerouslySetInnerHTML={{ __html: linkify(chat.content) }} />
      </div>
    ));
  };*/


  /*
  const formatConversationHistory = () => {
    if (displayFromConvo) {
      return conversationHistory.map((chat, index) => (
        <div key={index} className={chat.role === 'user' ? 'queryStyle' : 'responseStyle'}>
          <span className={chat.role === 'user' ? 'queryAuthorStyle' : 'responseAuthorStyle'}>
            {chat.role === 'user' ? 'User' : 'ChatGPT'}:
          </span>
          <div dangerouslySetInnerHTML={{ __html: linkify(chat.content) }} />
        </div>
      ));
    }
    // Render the current query and response if not displaying from saved convo
    return (
      <>
        {currentQuery && (
          <div className="queryStyle">
            <span className="queryAuthorStyle">{"User:"}</span>
            <div dangerouslySetInnerHTML={{ __html: linkify(currentQuery) }} />
          </div>
        )}
        {response && (
          <div className="responseStyle">
            <span className="responseAuthorStyle">{"ChatGPT:"}</span>
            <div dangerouslySetInnerHTML={{ __html: linkify(response) }} />
          </div>
        )}
      </>
    );
  };*/

/*

// ... existing handleUserQuery and other related functions ...
  const handleUserQuery = (query) => {
    setError(''); // Reset error message
    setIsLoading(true);
    setResponse('');
    setCurrentQuery(query); 
    currentQueryRef.current = query; 
    console.log("webSocket.send: " + JSON.stringify({ action: 'sendMessage', data: query }))

    const userMessage = {
      role: 'user',
      content: query
    };

    // Add the user message to the conversation history  -This is what I had before:
    //const updatedConversationHistory = [...conversationHistory, userMessage];
    //setConversationHistory(updatedConversationHistory);


    setConversationHistory((prevHistory) => {
      const userMessage = { role: 'user', content: query };
      return [...prevHistory, userMessage];
    });


    if (webSocket && webSocket.readyState === WebSocket.OPEN) {

        webSocket.send(JSON.stringify({
            action: 'sendMessage',
            data: query,
            convo: updatedConversationHistory 
        }));
    } else {
        console.error('WebSocket is not open. State:', webSocket ? webSocket.readyState : 'Not Initialized');
        setError('WebSocket connection not established');
        setIsLoading(false);
    }
  };



  const formatChatText = (text) => {
    // Replace newline characters with HTML line breaks
    const formattedText = text.replace(/\n/g, '<br />');
    const linkedText = linkify(formattedText);
    return <div dangerouslySetInnerHTML={{ __html: linkedText }} />;
  };

//const socket = new WebSocket('wss://0iw83igde0.execute-api.us-west-2.amazonaws.com/production/'); // old socket

const chatBubbleStyle = {
  padding: '10px',
  margin: '10px 0',
  borderRadius: '10px',
  backgroundColor: 'white',
  maxWidth: '94%',
  border: '1px solid lightgrey',
  fontFamily: '"Roboto", sans-serif',
};

const queryStyle = {
  ...chatBubbleStyle,
  alignSelf: 'flex-start',
};


const responseStyle = {
  ...chatBubbleStyle,
  alignSelf: 'flex-end',
};

const queryAuthorStyle = {
  color: '#6495ED', // Hexadecimal value for 
  fontWeight: '600', // Semi-bold; 600 is roughly equivalent to semi-bold
  fontSize: '0.9em', // Slightly smaller text
};

const responseAuthorStyle = {
  color: '#DDA0DD',
  fontWeight: '600', // Semi-bold
  fontSize: '0.9em', // Slightly smaller text
};

const chatBoxContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Align children to start and end of container
  minHeight: '100vh', // Make container full height of viewport
  position: 'relative', // For the sticky positioning of chat input
};

const chatHistoryStyle = {
  overflowY: 'auto',
  padding: '20px',
  marginBottom: 'auto', // Pushes everything below it down
};

const chatInputContainerStyle = {
  position: 'sticky', // Sticky or fixed based on your preference
  bottom: '0', // Stick to the bottom
  padding: '10px',
  backgroundColor: 'white', // Or any color you prefer
};
*/


/*
return (
  <div style={chatBoxContainerStyle}>
    {chatTitle && <h3 style={{ textAlign: 'center' }}>{chatTitle}</h3>}
    
    <div style={chatHistoryStyle}>
      {currentQuery && (
        <div style={queryStyle}>
          <span style={queryAuthorStyle}>
            {user && user.displayName ? user.displayName : "Anonymous"}:
          </span>
          {formatChatText(currentQuery)}
        </div>
      )}
      {response && (
        <div style={responseStyle}>
          <span style={responseAuthorStyle}>chatgpt-3.5-turbo:</span>
          {formatChatText(response)}
        </div>
      )}
        {wikiInfo && showWikiButton && (
          <div style={{...responseStyle, backgroundColor: '#f0f0f0'}}>
            <span style={{...responseAuthorStyle, color: '#008000'}}>Wikipedia Info:</span>
            <div dangerouslySetInnerHTML={{ __html: linkify(wikiInfo) }} />
          </div>
        )}
        {wikiTopic && (
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <button onClick={handleWikiButtonClick}>
                  Wikipedia Info
                </button>
              </div>
        )}
    </div>
    
    <div style={chatInputContainerStyle}>
      
      {isLoading && <div>Loading... <div className="spinner"></div></div>}
      {error && <div className="error">{error}</div>}
      <ChatGPTInput onSubmit={handleUserQuery} />
    </div>
     
  </div>
);
*/

/*
const separatorStyle = {
  height: '20px',
  height: '1px',
  backgroundColor: '#F5F5F5',
  margin: '20px 0', // Adjust as needed
};

const containerPaddingStyle = {
  padding: '20px'
}; */

  //const [chatHistory, setChatHistory] = useState([]);
  //const chatHistoryRef = useRef(null);
  //const [nextToken, setNextToken] = useState(undefined);
  
  //useEffect(() => {
  //  console.log("nextToken:", nextToken);
  //}, []); // Empty dependency array ensures it runs only once
  /*
  const isScrollAtBottom = () => {
    //console.log('in isScrollAtBottom');
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= docHeight;
  };
*/


/*
const formatQueryText = (chat) => {
// Check if urlSrc exists and query matches the condition
if (chat.urlSrc && chat.authorDisplayName === "mChatAI-Web-Summarizer") {
  // Create a clickable link with the chat's friendly name
  return (
    <>
      <br />
      <a href={chat.urlSrc} target="_blank" rel="noopener noreferrer">
        {chat.query}
      </a>
    </>
  );
} 
else {
  // If condition is not met, return the query text as is
  return formatChatText (chat.query);
}
};*/

/*
const getDisplayName = (chat) => {
  if (chat.authorDisplayName) {
    return chat.authorDisplayName;
  } else if (chat.authorID) {
    return chat.authorID;
  } else {
    return "Anonymous";
  }
};

const getModelName = (chat) => {
  if (chat.gptModel) {
    return chat.gptModel;
  } else {
    return "ChatGPT";
  }
};

const formatImage = (chat) => {
  if (chat.imgUrl) {
    return (
      <img 
        src={chat.imgUrl} 
        alt="Chat Image" 
        style={{ maxWidth: '100%', maxHeight: '200px' }}
        onError={(e) => e.target.style.display = 'none'} // Hides the image element if the link is broken
      />
    );
  }
  return null;
};
*/