import React, { useEffect, useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

const signUpConfig = {
  header: 'Create a new account',
  signUpFields: [
    {
      label: 'Display Name',
      key: 'custom:displayName',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
  ]
};

const SignIn = () => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [newDisplayName, setNewDisplayName] = useState('');

  useEffect(() => {
    checkUser();
  }, []);

    const checkUser = async () => {
    try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
        setDisplayName(userData.attributes['custom:displayName'] || userData.username);
    } catch (error) {
        console.log('Error fetching user', error);
    }
    };

    const handleSignOut = async () => {
    try {
        await Auth.signOut();
        setUser(null);
    } catch (error) {
        console.error('Error signing out: ', error);
    }
    };

    const handleDisplayNameChange = (event) => {
    setNewDisplayName(event.target.value);
    };

    const updateDisplayName = async () => {
    try {
        const updatedAttributes = {
        'custom:displayName': newDisplayName
        };
        await Auth.updateUserAttributes(user, updatedAttributes);
        setDisplayName(newDisplayName);
        setNewDisplayName(''); // Clear input after update
    } catch (error) {
        console.error('Error updating display name:', error);
    }
    };

    const containerStyle = {
        padding: '20px', // Adds padding around the container
        marginLeft: '20px' // Moves the content to the right
    };

    const buttonStyle = {
        marginRight: '10px', // Adds spacing between buttons
    };

    if (user) {
        return (
        <div style={containerStyle}>
            <p>Welcome, {displayName}</p>
            <input 
            type="text" 
            value={newDisplayName} 
            onChange={handleDisplayNameChange} 
            placeholder="Display Name"
            />
            <button style={buttonStyle} onClick={updateDisplayName}>Update Display Name</button>
            <p style={{ marginBottom: '100px' }}> </p>
            <p><button onClick={handleSignOut}>Sign Out</button></p>
        </div>
        );
    }
  
    return <div style={containerStyle}>{/* Sign-in/up form will be displayed by withAuthenticator */}</div>;
};

export default withAuthenticator(SignIn, { signUpConfig });
