/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChatResponse = /* GraphQL */ `
  query GetChatResponse($id: ID!) {
    getChatResponse(id: $id) {
      id
      query
      response
      createdAt
      authorDisplayName
      authorID
      gptModel
      systemCmd
      convo
      urlSrc
      imgUrl
      category
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatResponses = /* GraphQL */ `
  query ListChatResponses(
    $id: ID
    $filter: ModelChatResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        query
        response
        createdAt
        authorDisplayName
        authorID
        gptModel
        systemCmd
        convo
        urlSrc
        imgUrl
        category
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatResponses = /* GraphQL */ `
  query SyncChatResponses(
    $filter: ModelChatResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        query
        response
        createdAt
        authorDisplayName
        authorID
        gptModel
        systemCmd
        convo
        urlSrc
        imgUrl
        category
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listChatResponsesByAuthor = /* GraphQL */ `
  query ListChatResponsesByAuthor(
    $authorID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatResponsesByAuthor(
      authorID: $authorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        query
        response
        createdAt
        authorDisplayName
        authorID
        gptModel
        systemCmd
        convo
        urlSrc
        imgUrl
        category
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listChatResponsesByCategory = /* GraphQL */ `
  query ListChatResponsesByCategory(
    $category: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatResponsesByCategory(
      category: $category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        query
        response
        createdAt
        authorDisplayName
        authorID
        gptModel
        systemCmd
        convo
        urlSrc
        imgUrl
        category
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWebScraped = /* GraphQL */ `
  query GetWebScraped($id: ID!) {
    getWebScraped(id: $id) {
      id
      scrapeID
      url
      content
      scrapeDate
      category
      subCategory
      embeddings {
        items {
          id
          webScrapedID
          embeddingVector
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWebScrapeds = /* GraphQL */ `
  query ListWebScrapeds(
    $id: ID
    $filter: ModelWebScrapedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWebScrapeds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWebScrapeds = /* GraphQL */ `
  query SyncWebScrapeds(
    $filter: ModelWebScrapedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWebScrapeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const webScrapedById = /* GraphQL */ `
  query WebScrapedById(
    $scrapeID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWebScrapedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    webScrapedById(
      scrapeID: $scrapeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWebScrapedOpenAIEmbedded = /* GraphQL */ `
  query GetWebScrapedOpenAIEmbedded($id: ID!) {
    getWebScrapedOpenAIEmbedded(id: $id) {
      id
      webScrapedID
      embeddingVector
      lastUpdated
      webScraped {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWebScrapedOpenAIEmbeddeds = /* GraphQL */ `
  query ListWebScrapedOpenAIEmbeddeds(
    $id: ID
    $filter: ModelWebScrapedOpenAIEmbeddedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWebScrapedOpenAIEmbeddeds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        webScrapedID
        embeddingVector
        lastUpdated
        webScraped {
          id
          scrapeID
          url
          content
          scrapeDate
          category
          subCategory
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWebScrapedOpenAIEmbeddeds = /* GraphQL */ `
  query SyncWebScrapedOpenAIEmbeddeds(
    $filter: ModelWebScrapedOpenAIEmbeddedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWebScrapedOpenAIEmbeddeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        webScrapedID
        embeddingVector
        lastUpdated
        webScraped {
          id
          scrapeID
          url
          content
          scrapeDate
          category
          subCategory
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const embeddedById = /* GraphQL */ `
  query EmbeddedById(
    $webScrapedID: ID!
    $lastUpdated: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebScrapedOpenAIEmbeddedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    embeddedById(
      webScrapedID: $webScrapedID
      lastUpdated: $lastUpdated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        webScrapedID
        embeddingVector
        lastUpdated
        webScraped {
          id
          scrapeID
          url
          content
          scrapeDate
          category
          subCategory
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
