import React, { useState, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';

function ChatGPTInput({ onSubmit }) {
  const [input, setInput] = useState('');
  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustHeight(e.target);
  };

  const adjustHeight = (textarea) => {
    textarea.style.height = 'auto'; // Reset height to calculate new scroll height
    const maxHeight = 200; // Maximum height in pixels or use '25vh' for viewport height
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
  };

  const handleSubmit = () => {
    onSubmit(input);
    setInput('');
    adjustHeight(textareaRef.current);
  };

  const containerStyle = {
    display: 'flex', // Use Flexbox for layout
    alignItems: 'center', // Align items vertically
    justifyContent: 'space-between',
    width: '75%',
    //justifyContent: 'center', // Center children horizontally
    //margin: '0 15%', // Margin to give breathing room on both sides
    //padding: '10px 0', // Padding at the top and bottom of the container
  };
  
  const inputStyle = {
    flexGrow: 1, // Allow input to grow to fill space
    marginRight: '10px', // Margin between input and button
    height: 'auto', // Adjust height automatically
    padding: '10px', // Padding inside the textarea for some space around the text
    border: '1px solid #ccc', // Give it a border
    borderRadius: '4px', // Rounded corners for the textarea
    minWidth: '0%', // Ensure it can shrink to zero if needed
    minWidth: '80%',
    
    //maxWidth: '80%', // Ensure it does not grow beyond 80% of the container
    // ... any other styles you want to keep...
  };
  
  const buttonStyle = {
    backgroundColor: 'blue', // Blue background for the button
    color: 'white', // White text color
    border: 'none', // Remove default border
    padding: '10px', // Padding inside the button
    cursor: 'pointer', // Change cursor to pointer on hover
    borderRadius: '4px', // Rounded corners for the button
    // ... any other styles you want to keep...
  };

  return (
    <div style={containerStyle}>
      <textarea
        ref={textareaRef}
        style={inputStyle}
        value={input}
        onChange={handleInputChange}
        placeholder="Enter your query"
        rows={1} // Start with one line
      />
      <button onClick={handleSubmit} style={buttonStyle}>
        <SendIcon />
      </button>
    </div>
  );

}

export default ChatGPTInput;
