import React, { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from './graphql/queries';
import { createChatResponse } from './graphql/mutations';
import ChatGPTInput from './ChatGPTInput'; // Move ChatGPTInput to its own file
import { Auth } from 'aws-amplify';
import './App.css';
import './ChatComponent.css';

const getCurrentUserInfo = async () => {
  try {
    const userInfo = await Auth.currentAuthenticatedUser();
    const displayName = userInfo.attributes['custom:displayName'] || userInfo.username;
    const authorID = userInfo.attributes.sub; // Cognito User Pool sub attribute is the user's unique ID
    return { displayName, authorID };
  } catch (error) {
    console.error('Error fetching user info:', error);
    return { displayName: 'Anonymous', authorID: null };
  }
};

const storeChatGPTResponse = async (query, chatResponse) => {
  const { displayName, authorID } = await getCurrentUserInfo();
  
  const responseDetails = {
    id: chatResponse.id,
    query, 
    response: chatResponse,
    createdAt: new Date().toISOString(),
    authorDisplayName: displayName,
    authorID: authorID,
    gptModel: "gpt-3.5-turbo",
    category: "ChatResponse",
  };

  await API.graphql(graphqlOperation(createChatResponse, { input: responseDetails }));
};

const FeedPage = () => {
  //const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //const [webSocket, setWebSocket] = useState(null);
  const currentQueryRef = useRef('');
  const chatHistoryRef = useRef(null);
  const [nextToken, setNextToken] = useState(undefined);
  
  useEffect(() => {
    console.log("nextToken:", nextToken);
  }, []); // Empty dependency array ensures it runs only once
  
  const isScrollAtBottom = () => {
    //console.log('in isScrollAtBottom');
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    return windowBottom >= docHeight;
  };

  const fetchMoreChatHistory = async () => {
    //console.log("fetchMoreChatHistory, nextToken:", nextToken);

    if (!nextToken) return; // Exit if there's no more data to fetch
  
    try {

      // old code:
      //const result = await API.graphql(graphqlOperation(queries.listChatResponses, {
      //  limit: 10, // The number of items to fetch
      //  nextToken: nextToken, // Pass the current nextToken
      //}));

      const chatData = await API.graphql(graphqlOperation(queries.listChatResponsesByCategory, {
        category: 'ChatResponse', // Your category value
        sortDirection: 'DESC', // Assuming you want the newest items first
        // createdAt: {  },
        limit: 10, // Adjust the limit as needed
        nextToken: nextToken, // For pagination Pass the current nextToken
        // filter: {  }
      }));
  
      let newItems = chatData.data.listChatResponsesByCategory.items;
      //newItems = newItems.reverse();  // ideally it would be reverse instead of sort:
      newItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const newNextToken = chatData.data.listChatResponsesByCategory.nextToken;
      //const newNextToken = nextToken;
      console.log("Fetched newNextToken:", newNextToken);
      setNextToken(newNextToken); // Update the nextToken
  
      console.log('New items:', newItems); // Debug the fetched items

      // Ensure no duplicate keys
      const combinedItems = [...chatHistory, ...newItems].reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
  
      setChatHistory(combinedItems); // Update state with deduplicated items
      
    } catch (error) {
      console.error('Error fetching more chat history:', error);
    }
  };

  // Chat history fetching useEffect
  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        //console.log('In fetchChatHistory()')
        const chatData = await API.graphql(graphqlOperation(queries.listChatResponsesByCategory, {
          category: 'ChatResponse', // Your category value
          sortDirection: 'DESC', // Assuming you want the newest items first
          // createdAt: { },
          limit: 10, // Adjust the limit as needed
          //nextToken: nextToken, // For pagination
          // filter: { }
        }));
        
        // The items will be in ascending order; you might need to reverse them in your application
        const newItems = chatData.data.listChatResponsesByCategory.items;

        //console.log("fetchChatHistory: newItems:", newItems);
        //const newItems = items.reverse(); // This reverses the array to get the newest items first
        newItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setChatHistory(newItems);
        setNextToken(chatData.data.listChatResponsesByCategory.nextToken);
        //console.log("fetchChatHistory: nextToken:", chatData.data.listChatResponsesByCategory.nextToken); // Log this
      } catch (error) {
        console.error('ERROR fetchChatHistory():', error);
      }
    };

    fetchChatHistory();

  }, []); // Empty dependency array for chat history useEffect

  useEffect(() => {
    const handleScroll = () => {
      if (isScrollAtBottom()) {
        fetchMoreChatHistory();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [nextToken]); // Empty dependency array for scroll event

const getDisplayName = (chat) => {
  if (chat.authorDisplayName) {
    return chat.authorDisplayName;
  } else if (chat.authorID) {
    return chat.authorID;
  } else {
    return "Anonymous";
  }
};

const getModelName = (chat) => {
  if (chat.gptModel) {
    return chat.gptModel;
  } else {
    return "ChatGPT";
  }
};

const formatImage = (chat) => {
  if (chat.imgUrl) {
    return (
      <img 
        src={chat.imgUrl} 
        alt="Chat Image" 
        style={{ maxWidth: '100%', maxHeight: '200px' }}
        onError={(e) => e.target.style.display = 'none'} // Hides the image element if the link is broken
      />
    );
  }
  return null;
};

const linkify = (inputText) => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\._]+@[a-zA-Z0-9\-\._]+\.[a-zA-Z]{2,}))/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

const formatChatText = (text) => {
  // Replace newline characters with HTML line breaks
  const formattedText = text.replace(/\n/g, '<br />');
  const linkedText = linkify(formattedText);
  return <div dangerouslySetInnerHTML={{ __html: linkedText }} />;
};

const formatQueryText = (chat) => {
// Check if urlSrc exists and query matches the condition
if (chat.urlSrc && chat.authorDisplayName === "mChatAI-Web-Summarizer") {
  // Create a clickable link with the chat's friendly name
  return (
    <>
      <br />
      <a href={chat.urlSrc} target="_blank" rel="noopener noreferrer">
        {chat.query}
      </a>
    </>
  );
} 
else {
  // If condition is not met, return the query text as is
  return formatChatText (chat.query);
}
};



return (
  <div className="containerPaddingStyle">
    
    {isLoading && <div>Loading... <div className="spinner"></div></div>}
    
    {error && <div className="error">{error}</div>}
    <div>
      <h2>AI Chats & News Feed Generator</h2>
      <div ref={chatHistoryRef}>
        {chatHistory.map((chat, index) => (
          <div key={chat.id}>
            <div className="queryStyle">
              <span className="queryAuthorStyle">{getDisplayName(chat)}:</span> {formatQueryText(chat)}
            </div>
            {formatImage(chat)} 
            <div className="responseStyle">
              <span className="responseAuthorStyle">{getModelName(chat)}:</span> {formatChatText(chat.response)}
            </div>
            
            {index < chatHistory.length - 1 && <div className="separatorStyle"></div>}
          </div>
        ))}
      </div>
    </div>
  </div>
);

}
export default FeedPage;


/*
const chatBubbleStyle = {
  padding: '10px',
  margin: '10px 0',
  borderRadius: '10px',
  backgroundColor: 'white',
  maxWidth: '94%',
  border: '1px solid lightgrey',
  fontFamily: '"Roboto", sans-serif',
};

const queryStyle = {
  ...chatBubbleStyle,
  alignSelf: 'flex-start',
};


const responseStyle = {
  ...chatBubbleStyle,
  alignSelf: 'flex-end',
};

const separatorStyle = {
  height: '20px',
  height: '1px',
  backgroundColor: '#F5F5F5',
  margin: '20px 0', // Adjust as needed
};



const queryAuthorStyle = {
  color: '#6495ED', // Hexadecimal value for 
  fontWeight: '600', // Semi-bold; 600 is roughly equivalent to semi-bold
  fontSize: '0.9em', // Slightly smaller text
};

const responseAuthorStyle = {
  color: '#DDA0DD',
  fontWeight: '600', // Semi-bold
  fontSize: '0.9em', // Slightly smaller text
};


const containerPaddingStyle = {
  padding: '20px'
};
*/
/*
return (
  <div style={containerPaddingStyle}>
    
    {isLoading && <div>Loading... <div className="spinner"></div></div>}
    
    {error && <div className="error">{error}</div>}
    <div>
      <h2>AI Chats & News Feed Generator</h2>
      <div ref={chatHistoryRef}>
        {chatHistory.map((chat, index) => (
          // ... existing chat history rendering logic ...
          <div key={chat.id}>
            <div style={queryStyle}>
              <span style={queryAuthorStyle}>{getDisplayName(chat)}:</span> {formatQueryText(chat)}
            </div>
            {formatImage(chat)} 
            <div style={responseStyle}>
              <span style={responseAuthorStyle}>{getModelName(chat)}:</span> {formatChatText(chat.response)}
            </div>
            
            {index < chatHistory.length - 1 && <div style={separatorStyle}></div>}
          </div>
        ))}
      </div>
    </div>
  </div>
);
*/