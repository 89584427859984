import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsconfig);

// Use createRoot to render the App
const container = document.getElementById('root');
const root = createRoot(container); // Create a root.
root.render( // Use the render method on the root.
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

//import { studioTheme } from "./ui-components";
//import { AmplifyProvider } from '@aws-amplify/ui-react';


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
