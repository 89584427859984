import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const checkUser = async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        setUser(userData);
        setDisplayName(userData.attributes['custom:displayName'] || userData.username);
      } catch (error) {
        console.log('Error fetching user', error);
        setUser(null);
      }
    };

    checkUser();
  }, []);

  return { user, displayName };
};

export default useAuth;
