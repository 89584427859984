/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChatResponse = /* GraphQL */ `
  mutation CreateChatResponse(
    $input: CreateChatResponseInput!
    $condition: ModelChatResponseConditionInput
  ) {
    createChatResponse(input: $input, condition: $condition) {
      id
      query
      response
      createdAt
      authorDisplayName
      authorID
      gptModel
      systemCmd
      convo
      urlSrc
      imgUrl
      category
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatResponse = /* GraphQL */ `
  mutation UpdateChatResponse(
    $input: UpdateChatResponseInput!
    $condition: ModelChatResponseConditionInput
  ) {
    updateChatResponse(input: $input, condition: $condition) {
      id
      query
      response
      createdAt
      authorDisplayName
      authorID
      gptModel
      systemCmd
      convo
      urlSrc
      imgUrl
      category
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatResponse = /* GraphQL */ `
  mutation DeleteChatResponse(
    $input: DeleteChatResponseInput!
    $condition: ModelChatResponseConditionInput
  ) {
    deleteChatResponse(input: $input, condition: $condition) {
      id
      query
      response
      createdAt
      authorDisplayName
      authorID
      gptModel
      systemCmd
      convo
      urlSrc
      imgUrl
      category
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWebScraped = /* GraphQL */ `
  mutation CreateWebScraped(
    $input: CreateWebScrapedInput!
    $condition: ModelWebScrapedConditionInput
  ) {
    createWebScraped(input: $input, condition: $condition) {
      id
      scrapeID
      url
      content
      scrapeDate
      category
      subCategory
      embeddings {
        items {
          id
          webScrapedID
          embeddingVector
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWebScraped = /* GraphQL */ `
  mutation UpdateWebScraped(
    $input: UpdateWebScrapedInput!
    $condition: ModelWebScrapedConditionInput
  ) {
    updateWebScraped(input: $input, condition: $condition) {
      id
      scrapeID
      url
      content
      scrapeDate
      category
      subCategory
      embeddings {
        items {
          id
          webScrapedID
          embeddingVector
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWebScraped = /* GraphQL */ `
  mutation DeleteWebScraped(
    $input: DeleteWebScrapedInput!
    $condition: ModelWebScrapedConditionInput
  ) {
    deleteWebScraped(input: $input, condition: $condition) {
      id
      scrapeID
      url
      content
      scrapeDate
      category
      subCategory
      embeddings {
        items {
          id
          webScrapedID
          embeddingVector
          lastUpdated
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWebScrapedOpenAIEmbedded = /* GraphQL */ `
  mutation CreateWebScrapedOpenAIEmbedded(
    $input: CreateWebScrapedOpenAIEmbeddedInput!
    $condition: ModelWebScrapedOpenAIEmbeddedConditionInput
  ) {
    createWebScrapedOpenAIEmbedded(input: $input, condition: $condition) {
      id
      webScrapedID
      embeddingVector
      lastUpdated
      webScraped {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWebScrapedOpenAIEmbedded = /* GraphQL */ `
  mutation UpdateWebScrapedOpenAIEmbedded(
    $input: UpdateWebScrapedOpenAIEmbeddedInput!
    $condition: ModelWebScrapedOpenAIEmbeddedConditionInput
  ) {
    updateWebScrapedOpenAIEmbedded(input: $input, condition: $condition) {
      id
      webScrapedID
      embeddingVector
      lastUpdated
      webScraped {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWebScrapedOpenAIEmbedded = /* GraphQL */ `
  mutation DeleteWebScrapedOpenAIEmbedded(
    $input: DeleteWebScrapedOpenAIEmbeddedInput!
    $condition: ModelWebScrapedOpenAIEmbeddedConditionInput
  ) {
    deleteWebScrapedOpenAIEmbedded(input: $input, condition: $condition) {
      id
      webScrapedID
      embeddingVector
      lastUpdated
      webScraped {
        id
        scrapeID
        url
        content
        scrapeDate
        category
        subCategory
        embeddings {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
